import React from "react"
import { Link } from "gatsby"
import { FaHome, FaChevronDown, FaGlasses } from "react-icons/fa"
import {
  breadcrumbs as breadcrumbsClass,
  hasDropdown,
  isDrop,
  current,
} from "./breadcrumb.module.css"
import { Helmet } from "react-helmet"
import linkBuilder from "../../modules/linkBuilder"

const Breadcrumb = ({ article, desk, deskCategory, desks, deskCategories }) => {
  const breadcrumbs = []

  breadcrumbs.push({
    name: "Home",
    link: "/",
  })

  if (desk) {
    //const altLinks = [];
    // for (let alt of desks) {
    //   //altLinks.push({ name: alt.name, link: linkBuilder.desk(alt) })
    // }
    breadcrumbs.push({
      name: desk.name,
      link: linkBuilder.desk(desk),
      //altLinks: altLinks,
      class: "desk",
    })
  }

  if (deskCategory) {
    //const altLinks = [];
    if (deskCategories) {
      // for (let alt of deskCategories) {
      //   //altLinks.push({ name: alt.name, link: linkBuilder.deskCategory(alt) })
      // }
    }
    breadcrumbs.push({
      name: deskCategory.name,
      link: linkBuilder.deskCategory(deskCategory),
      //altLinks: altLinks,
      class: "category",
    })
  }

  if (article) {
    breadcrumbs.push({
      name: article.title,
    })
  }

  let breadcrumbHtml = (
    <div className={breadcrumbsClass}>
      <ol id="breadcrumb">
        {breadcrumbs.map((item, index) => {
          if (index === 0)
            return (
              <li className="home" key={`breadcrumb-${index}`}>
                <Link to={item.link}>
                  <FaHome />
                </Link>
              </li>
            )
          else if (item.altLinks && item.altLinks.length)
            return (
              <li
                className={`${hasDropdown} ${item.class || ""}`}
                key={`breadcrumb-${index}`}
              >
                <span>
                  <Link to={item.link}>{item.name}</Link> <FaChevronDown />
                </span>
                <div className={isDrop}>
                  <ul className={`list pl0`}>
                    {item.altLinks.map((alt, altIndex) => {
                      return (
                        <li
                          key={`breadcrumb-${index}-${altIndex}`}
                          className={item.class}
                        >
                          <Link to={alt.link}>{alt.name}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </li>
            )
          else if (!item.link)
            return (
              <li className={current} key={`breadcrumb-${index}`}>
                <FaGlasses /> Dit artikel
              </li>
            )
          else
            return (
              <li
                className={`${hasDropdown} ${item.class || ""}`}
                key={`breadcrumb-${index}`}
              >
                <Link to={item.link}>{item.name}</Link>
              </li>
            )
        })}
      </ol>
    </div>
  )

  // Build ld+json
  const itemListElement = []
  for (let index in breadcrumbs) {
    if (parseInt(index) !== breadcrumbs.length - 1)
      itemListElement.push({
        "@type": "ListItem",
        position: parseInt(index) + 1,
        item: {
          "@id": `https://mkbservicedesk.nl` + breadcrumbs[index].link,
          name: breadcrumbs[index].name,
        },
      })
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": ${JSON.stringify(itemListElement)}
        }
      `}</script>
      </Helmet>
      {breadcrumbHtml}
    </>
  )
}

export default Breadcrumb
